import { generate } from "random-words";
import React, { useEffect } from "react";

export const SpellingBee: React.FC<{}> = () => {
  const [words, setWords] = React.useState<string[]>([]);
  const [letters, setLetters] = React.useState<string[]>([]);
  const [correctWords, setCorrectWords] = React.useState<string[]>([]);

  useEffect(() => {
    const newWords = generate({
      exactly: 5,
      minLength: 3,
      maxLength: 3,
    }) as string[];
    setWords(newWords);
    newWords.forEach((word) => {
      const tempLetters = word.split("");
      setLetters((letters) => [...letters, ...tempLetters]);
    });
  }, []);

  const generateUnderscores = (length: number): string[] => {
    return Array(length).fill(" _");
  };

  return (
    <div className="container">
      <div className="text-3xl">Spelling Bee</div>
      <div>Words</div>
      <div>
        {(words ?? []).map((word, index) =>
          correctWords.includes(word) ? (
            <div>{word}</div>
          ) : (
            <div>
              {word.split("", 1)}
              {generateUnderscores(word.length - 1)}
            </div>
          ),
        )}
      </div>
      <div>Letters</div>
      <div>
        {(letters ?? []).map((letter, index) => (
          <div>{letter}</div>
        ))}
      </div>
    </div>
  );
};
