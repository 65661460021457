import React from "react";
import { useGridGameContext } from "../context/GridGameContext";
import Cell from "./Cell";

interface GridProps {}

const Grid: React.FC<GridProps> = () => {
  const {
    mistakes,
    MAX_MISTAKES,
    rowSums,
    colSums,
    grid,
    setGrid,
    gamemode,
    setHasGameStarted,
    setMistakes,
  } = useGridGameContext();

  const handleCellClick = (num: any, i: number, j: number) => {
    setHasGameStarted(true);
    if (
      ((!num.isRandom && gamemode === "erase") ||
        (num.isRandom && gamemode === "set")) &&
      !num.isChecked
    ) {
      setMistakes(mistakes + 1);
      if (mistakes + 1 >= MAX_MISTAKES) {
        // Reset the game
        alert("Game Over!");
        // resetGame();
      }
    }
    if (!num.isRandom) {
      if (gamemode === "set") {
        const newGrid = [...grid];
        newGrid[i][j] = {
          ...newGrid[i][j],
          isChecked: true,
        };
        setGrid(newGrid);
        console.log(parseInt(rowSums[i].subtotal), num.value);
        rowSums[i] = {
          ...rowSums[i],
          subtotal: parseInt(rowSums[i].subtotal) + num.value,
        };

        colSums[j] = {
          ...colSums[j],
          subtotal: parseInt(colSums[j].subtotal) + num.value,
        };
      }
    } else {
      // Set or erase the number
      if (gamemode === "erase") {
        const newGrid = [...grid];
        newGrid[i][j] = {
          ...newGrid[i][j],
          value: "",
        };
        setGrid(newGrid);
      }
    }
  };

  return (
    <div className="max-w-sm justify-self-center">
      {/* Row Sums */}
      <div className="text-primary flex center justify-center gap-4 pb-5">
        <Cell content="" />
        {rowSums.map((sum, i) => (
          <Cell key={i} content={sum.total} subcontent={sum.subtotal} />
        ))}
      </div>
      <div className="flex justify-center gap-4">
        {/* COL SUMS */}
        <div className="text-primary gap-4 flex justify-center flex-col">
          {colSums.map((sum, i) => (
            <Cell key={i} content={sum.total} subcontent={sum.subtotal} />
          ))}
        </div>
        {grid.map((row, i) => (
          <div key={i} className="flex flex-col gap-4">
            {row.map((num: any, j) => (
              <Cell
                key={j}
                content={num.value}
                className={` ${
                  !!num.isChecked
                    ? "border-[#ffff83] border-[0.5px] border-solid bg-[#606007] shadow"
                    : ""
                }`}
                onClick={() => handleCellClick(num, i, j)}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Grid;
