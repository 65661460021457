import moment from "moment";
import React, { createContext, useContext } from "react";
import { GridGameContextProps, Sum } from "./types";

const GridGameContext = createContext<GridGameContextProps>({
  blankSpaceLikelihood: "",
  setBlankSpaceLikelihood: (blankSpaceLikelihood: string) => {},
  gridSize: 0,
  setGridSize: (gridSize: number) => {},
  mistakes: 0,
  setMistakes: (mistakes: number) => {},
  MAX_MISTAKES: 0,
  rowSums: [],
  setRowSums: (rowSums: Sum[]) => {},
  colSums: [],
  setColSums: (colSums: Sum[]) => {},
  grid: [],
  setGrid: (grid: any[][]) => {},
  showAnswers: false,
  setShowAnswers: (showAnswers: boolean) => {},
  gamemode: "set",
  setGamemode: (gamemode: string) => {},
  hasGameStarted: false,
  setHasGameStarted: (hasGameStarted: boolean) => {},
  currentTime: moment(),
  setCurrentTime: (currentTime: moment.Moment) => {},
  timeGameStarted: moment(),
  setTimeGameStarted: (timeGameStarted: moment.Moment) => {},
});

type GridGameProviderProps = {
  children: React.ReactElement;
};

export const GridGameProvider = ({ children }: GridGameProviderProps) => {
  const MAX_MISTAKES = 3;
  const GRID_SIZE = 5;
  const BLANK_SPACE_LIKELIHOOD = "0.2";

  const [blankSpaceLikelihood, setBlankSpaceLikelihood] =
    React.useState<string>(BLANK_SPACE_LIKELIHOOD);
  const [gridSize, setGridSize] = React.useState<number>(GRID_SIZE);
  const [mistakes, setMistakes] = React.useState<number>(0);
  const [rowSums, setRowSums] = React.useState<Sum[]>([]);
  const [colSums, setColSums] = React.useState<Sum[]>([]);
  const [grid, setGrid] = React.useState<any[][]>([]);
  const [showAnswers, setShowAnswers] = React.useState<boolean>(false);
  const [gamemode, setGamemode] = React.useState<string>("set"); // erase or set
  const [hasGameStarted, setHasGameStarted] = React.useState<boolean>(false); // erase or set
  const [currentTime, setCurrentTime] = React.useState(moment());
  const [timeGameStarted, setTimeGameStarted] = React.useState(moment());

  return (
    <GridGameContext.Provider
      value={{
        blankSpaceLikelihood,
        setBlankSpaceLikelihood,
        gridSize,
        setGridSize,
        mistakes,
        setMistakes,
        MAX_MISTAKES,
        rowSums,
        setRowSums,
        colSums,
        setColSums,
        grid,
        setGrid,
        showAnswers,
        setShowAnswers,
        gamemode,
        setGamemode,
        hasGameStarted,
        setHasGameStarted,
        currentTime,
        setCurrentTime,
        timeGameStarted,
        setTimeGameStarted,
      }}
    >
      {children}
    </GridGameContext.Provider>
  );
};

export const useGridGameContext = (): GridGameContextProps =>
  useContext(GridGameContext);
