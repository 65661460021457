import "./style.css";

type HeaderProps = {
  onClick: () => void;
};

/**
 * React component for the Header Section.
 */
export const Header = (props: HeaderProps) => {
  return (
    <header className="header">
      <h2 onClick={props.onClick}>New Game</h2>
    </header>
  );
};
