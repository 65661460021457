import React, { createContext, useContext, useState } from "react";

type WordleContextProps = {
  word: string;
  setWord: (word: string) => void;
  remainingGuesses: number;
  setRemainingGuesses: (remainingGuesses: number) => void;
  correctWord: string;
  setCorrectWord: (correctWord: string) => void;
  guesses: any[][];
  setGuesses: (guesses: any[][]) => void;
};

const WordleContext = createContext<WordleContextProps>({
  word: "",
  setWord: (word: string) => {},
  remainingGuesses: 0,
  setRemainingGuesses: (remainingGuesses: number) => {},
  correctWord: "",
  setCorrectWord: (correctWord: string) => {},
  guesses: [],
  setGuesses: (guesses: any[][]) => {},
});

type WordleProviderProps = {
  children: React.ReactElement;
};

export const WordleProvider = ({ children }: WordleProviderProps) => {
  const MAX_GUESSES = 6;
  const MAX_LETTERS = 5;

  const [word, setWord] = useState<string>("");
  const [remainingGuesses, setRemainingGuesses] = useState<number>(MAX_GUESSES);
  const [correctWord, setCorrectWord] = useState<string>("");
  const [guesses, setGuesses] = useState<any[][]>([]);

  return (
    <WordleContext.Provider
      value={{
        word,
        setWord,
        remainingGuesses,
        setRemainingGuesses,
        correctWord,
        setCorrectWord,
        guesses,
        setGuesses,
      }}
    >
      {children}
    </WordleContext.Provider>
  );
};

export const useWordleContext = (): WordleContextProps =>
  useContext(WordleContext);
