import React from "react";
import { useGridGameContext } from "../context/GridGameContext";

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const { mistakes, MAX_MISTAKES } = useGridGameContext();

  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        paddingBottom: 30,
        gap: 10,
      }}
    >
      Mistakes:
      <div style={{ fontWeight: "bold" }}>
        {mistakes} / {MAX_MISTAKES}
      </div>
    </div>
  );
};

export default Header;
