import moment from "moment";
import React, { useEffect } from "react";
import "../App.css";
import { getRandomBoolean } from "../utils";
import { useGridGameContext } from "./context/GridGameContext";
import Controls from "./Controls";
import Grid from "./Grid";
import Header from "./Header";
import Settings from "./Settings";
import "./styles.css";

export const GridGame: React.FC<{}> = () => {
  const {
    blankSpaceLikelihood,
    gridSize,
    mistakes,
    setMistakes,
    MAX_MISTAKES,
    setRowSums,
    setColSums,
    grid,
    setGrid,
    hasGameStarted,
    setTimeGameStarted,
    setGridSize,
  } = useGridGameContext();

  const generateGrid = (size: number): number[][] | void => {
    const grid = [];
    const rowSums = new Array(size).fill({ total: 0, subtotal: 0 });
    const colSums = new Array(size).fill({ total: 0, subtotal: 0 });

    // Maximum value to ensure sums are less than 4 * size
    const maxNumber = Math.floor((9 * size) / size); // This will effectively be 3 for size 1-3
    const maxInRowOrCol = Math.min(maxNumber, 9); // Ensure we stay within single-digit bounds

    // Generate the grid with single-digit numbers
    for (let i = 0; i < size; i++) {
      const row = [];
      for (let j = 0; j < size; j++) {
        let num;
        if (getRandomBoolean(parseFloat(blankSpaceLikelihood))) {
          // Generate an incorrect cell
          num = {
            isRandom: true,
            value: Math.floor(Math.random() * maxInRowOrCol) + 1,
          };
        } else {
          // Generate an correct cell
          num = {
            isRandom: false,
            value: Math.floor(Math.random() * maxInRowOrCol) + 1,
          };
          rowSums[i] = {
            ...rowSums[i],
            total: rowSums[i].total + num.value,
          };
          colSums[j] = {
            ...colSums[j],
            total: colSums[j].total + num.value,
          };
        }
        // Generate a number between 1 and maxInRowOrCol
        row.push(num);
      }
      grid.push(row);
    }

    // Ensure that the sums of each row and column are less than 4 * size
    while (
      rowSums.some((sum) => sum.total >= 4 * size) ||
      colSums.some((sum) => sum.total >= 4 * size)
    ) {
      return generateGrid(size); // Regenerate grid if sums exceed the limit
    }

    // Display the grid and sums
    setGrid(grid);

    setRowSums(rowSums);
    setColSums(colSums);
  };

  const resetGame = () => {
    generateGrid(gridSize);
    setTimeGameStarted(moment());
    setMistakes(0);
  };

  const handleWindowSizeChange = () => {
    if (window.innerWidth < 768) {
      setGridSize(5);
    }
  };

  useEffect(() => {
    resetGame();
  }, [gridSize, blankSpaceLikelihood]);

  useEffect(() => {
    // check grid to see if all isRandom cells values are empty strings and all non-random cells are checked
    const isGridComplete = grid.every((row) =>
      row.every((cell) => (cell.isRandom ? cell.value === "" : cell.isChecked)),
    );
    if (hasGameStarted && isGridComplete && mistakes < MAX_MISTAKES) {
      alert("You Win!");
      resetGame();
    }
  }, [grid]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <div className="min-h-screen content-center text-2xl text-white content-center">
      <Settings />
      <Header />
      <Grid />
      <Controls />
    </div>
  );
};
