import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { SudokuProvider } from "./context/SudokuContext";
import { Game } from "./Game";
import { GridGame } from "./GridGame";
import { GridGameProvider } from "./GridGame/context/GridGameContext";
import { GuessingGame } from "./GuessingGame";
import MathGame from "./MathGame";
import { SpellingBee } from "./SpellingBee";
import { Wordle } from "./Wordle";
import { WordleProvider } from "./Wordle/context/WordleContext";

/**
 * App is the root React component.
 */
export const App: React.FC<{}> = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <div className="flex flex-col text-white items-center justify-center min-h-screen font-bold text-2xl">
          <a href="/sudoku" className="hover:scale-105">
            Sudoku
          </a>
          <a href="/wordle" className="hover:scale-105">
            Wordle
          </a>
          <a href="/grid-game" className="hover:scale-105">
            Grid Game
          </a>
          <a href="/math-game" className="hover:scale-105">
            Math Game
          </a>
          <a href="/guessing-game">Guessing Game</a>
          {/* <a href="/spelling-bee">Spelling Bee</a> */}
        </div>
      ),
    },
    {
      path: "/sudoku",
      element: (
        <SudokuProvider>
          <Game />
        </SudokuProvider>
      ),
    },
    {
      path: "/wordle",
      element: (
        <WordleProvider>
          <Wordle />
        </WordleProvider>
      ),
    },
    {
      path: "/grid-game",
      element: (
        <GridGameProvider>
          <GridGame />
        </GridGameProvider>
      ),
    },
    {
      path: "/guessing-game",
      element: <GuessingGame />,
    },
    {
      path: "/spelling-bee",
      element: <SpellingBee />,
    },
    {
      path: "/math-game",
      element: <MathGame />,
    },
  ]);

  return (
    <div className="bg-[#323232FF] min-h-screen">
      <h1>
        <a
          href="/"
          className="text-white font-bold text-xl absolute top-5 left-5 z-10"
        >
          Will<span className="">.</span>
          <span className="text-[#2c8ff2]">fun</span>
        </a>
      </h1>
      <ToastContainer />
      <RouterProvider router={router} future={{ v7_startTransition: true }} />
    </div>
  );
};
