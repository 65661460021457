import React from "react";
import Tile from "../Tile";

interface GuessProps {
  guess: any;
}

const GuessLetter: React.FC<{ letter: { status: string; letter: string } }> = ({
  letter,
}) => {
  let color;

  if (letter.status === "correct") {
    color = "bg-[lightgreen]";
  } else if (letter.status === "present") {
    color = "bg-[gold]";
  } else {
    color = "bg-[lightcoral]";
  }

  return <Tile letter={letter.letter} color={color} />;
};

const Guess: React.FC<GuessProps> = ({ guess }) => {
  return (
    <div className="flex justify-center gap-2">
      {guess.map((letter: any, index: number) => (
        <GuessLetter letter={letter} key={index} />
      ))}
    </div>
  );
};

export default Guess;
