import React from "react";
import Tile from "../Tile";

interface GuessPlaceholderProps {}

const GuessPlaceholder: React.FC<GuessPlaceholderProps> = () => {
  return (
    <div className="flex justify-center gap-2">
      {[1, 2, 3, 4, 5].map((_: any, index: number) => (
        <Tile key={index} />
      ))}
    </div>
  );
};

export default GuessPlaceholder;
