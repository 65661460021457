import React from "react";
import { useGridGameContext } from "../context/GridGameContext";

interface SettingsProps {}

const Settings: React.FC<SettingsProps> = () => {
  const {
    blankSpaceLikelihood,
    setBlankSpaceLikelihood,
    gridSize,
    setGridSize,
  } = useGridGameContext();

  return (
    <div className="flex justify-center text-center pb-16 gap-8 sm:hidden">
      <div>
        <div>Filler Space Likelihood</div>
        <input
          type="range"
          max={0.9}
          min={0.1}
          step={0.1}
          value={blankSpaceLikelihood}
          className="w-48 self-center mt-2"
          onChange={(ev) => setBlankSpaceLikelihood(ev.target.value)}
        />
        <div>{parseFloat(blankSpaceLikelihood) * 100} %</div>
      </div>
      <div>
        <div>Grid Size</div>
        <select
          className="self-center text-black"
          value={gridSize}
          onChange={(ev) => setGridSize(Number(ev.target.value))}
        >
          <option value={4}>4x4</option>
          <option value={5}>5x5</option>
          <option value={6}>6x6</option>
          <option value={7}>7x7</option>
        </select>
      </div>
    </div>
  );
};

export default Settings;
