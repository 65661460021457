import {
  faArrowRight,
  faBackspace,
  faRefresh,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "../components/ui/Button";

const MathGame: React.FC = () => {
  const GAME_DURATION = 30;

  const [points, setPoints] = useState<number>(0);
  const [num1, setNum1] = useState<number>(Math.floor(Math.random() * 10));
  const [num2, setNum2] = useState<number>(Math.floor(Math.random() * 10));
  const [operation, setOperation] = useState<"+" | "/" | "-" | "*">("+");
  const [answers, setAnswers] = useState<any[]>([]);
  const [userAnswer, setUserAnswer] = useState<string>("");
  const [timeLeft, setTimeLeft] = useState<number>(GAME_DURATION);
  const operations: ("+" | "/" | "-" | "*")[] = ["+", "-", "*"];

  const checkAnswer = () => {
    let correctAnswer = null;
    switch (operation) {
      case "+":
        correctAnswer = num1 + num2;
        break;
      case "-":
        correctAnswer = num1 - num2;
        break;
      case "*":
        correctAnswer = num1 * num2;
        break;
      case "/":
        correctAnswer = num1 / num2;
        break;
    }

    // Prevent submitting after time is up
    if (timeLeft === 0) {
      return;
    }

    // Check if the answer is correct
    if (parseInt(userAnswer) === correctAnswer) {
      setAnswers([
        ...answers,
        {
          question: `${num1} + ${num2}`,
          correctAnswer,
          userAnswer,
          isCorrect: true,
        },
      ]);
      setPoints(points + 1);
    } else {
      setAnswers([
        ...answers,
        {
          question: `${num1} + ${num2}`,
          correctAnswer,
          userAnswer,
          isCorrect: false,
        },
      ]);
      setPoints(points - 1);
    }

    // Generate new question
    setNum1(Math.floor(Math.random() * 10));
    setNum2(Math.floor(Math.random() * 10));
    setOperation(operations[Math.floor(Math.random() * operations.length)]);
    setUserAnswer("");
  };

  useEffect(() => {
    if (timeLeft === 0) {
      setUserAnswer("");
      toast.error("Time's up!", { theme: "dark" });

      return;
    }

    const timer = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const resetGame = () => {
    setPoints(0);
    setNum1(Math.floor(Math.random() * 10));
    setNum2(Math.floor(Math.random() * 10));
    setAnswers([]);
    setUserAnswer("");
    setTimeLeft(GAME_DURATION);
  };

  return (
    <div className="h-screen max-w-sm text-white justify-self-center content-center relative">
      <div className="absolute right-4 top-4 flex gap-2">
        <Button title="New Game" onClick={resetGame} icon={faRefresh} />
        <Button
          title="Skip"
          disabled={timeLeft === 0}
          icon={faArrowRight}
          onClick={() => {
            setUserAnswer("-666");
            checkAnswer();
          }}
        />
      </div>
      <div className="aspect-square h-16 bg-white text-black rounded-full text-center content-center text-2xl font-bold absolute right-5 top-20">
        <span>{timeLeft}</span>
      </div>
      <div className="text-2xl font-bold text-center my-4 mt-42">
        Points: {points}
      </div>
      <p className="text-center text-3xl">
        {num1} {operation} {num2}
      </p>
      <div className="justify-self-center">
        <input
          type="text"
          disabled={timeLeft === 0}
          className="bg-lightgray p-2 rounded my-8 mr-2 text-black uppercase"
          value={userAnswer}
          onChange={(e) => setUserAnswer(e.target.value)}
          onKeyDownCapture={(ev) => {
            if (ev.key === "Enter") {
              checkAnswer();
            }
          }}
        />
        <Button
          title="Submit"
          onClick={checkAnswer}
          disabled={timeLeft === 0}
        />
      </div>
      <div className="flex flex-wrap gap-4 max-w-72 sm:max-w-full justify-center mx-11">
        {["1", "2", "3", "4", "5", "6", "7", "8", "9"].map((number, index) => (
          <button
            key={index}
            disabled={timeLeft === 0}
            className="aspect-square h-16 w-16 bg-lightgray text-black text-center content-center rounded text-xl"
            onClick={() => setUserAnswer(userAnswer + number)}
          >
            {number}
          </button>
        ))}
        <button
          disabled={timeLeft === 0}
          className="aspect-square h-16 w-16 bg-lightgray text-black text-center content-center rounded text-xl"
          onClick={() => setUserAnswer("")}
        >
          <FontAwesomeIcon icon={faX} />
        </button>
        <button
          disabled={timeLeft === 0}
          className="aspect-square h-16 w-16 bg-lightgray text-black text-center content-center rounded text-xl"
          onClick={() => setUserAnswer(userAnswer + "0")}
        >
          0
        </button>
        <button
          disabled={timeLeft === 0}
          className="aspect-square h-16 w-16 bg-lightgray text-black text-center content-center rounded text-xl"
          onClick={() => setUserAnswer(userAnswer.slice(0, -1))}
        >
          <FontAwesomeIcon icon={faBackspace} />
        </button>
      </div>
      {/* <div className="flex flex-wrap justify-center gap-2 mt-8 mx-11">
        {(answers ?? []).map((item, index) => (
          <div
            key={index}
            className={`h-6 w-6 aspect-square rounded-full ${
              item.isCorrect ? "bg-green" : "bg-red"
            }`}
          />
        ))}
      </div> */}
    </div>
  );
};

export default MathGame;
