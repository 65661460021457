import React from "react";
import { useGridGameContext } from "../../context/GridGameContext";

interface CellProps {
  content: string;
  subcontent?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  className?: string;
}

const Cell: React.FC<CellProps> = (
  props: CellProps &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
) => {
  const { mistakes, MAX_MISTAKES } = useGridGameContext();

  return (
    <div
      {...props}
      className="font-bold w-10 aspect-square text-center content-center relative"
      style={{
        ...props.style,
      }}
    >
      {props.subcontent ? (
        <div className="absolute top-[-2px] right-0 text-xs">
          {props.subcontent}
        </div>
      ) : null}
      {props.content}
    </div>
  );
};

export default Cell;
