import { faLightbulb, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { generate } from "random-words";
import React, { useEffect } from "react";
import Button from "../components/ui/Button";
import { useWordleContext } from "./context/WordleContext";
import Guess from "./Guess";
import GuessPlaceholder from "./GuessPlaceholder";

export const Wordle: React.FC<{}> = () => {
  const MAX_GUESSES = 6;
  const MAX_LETTERS = 5;

  const {
    word,
    setWord,
    remainingGuesses,
    setRemainingGuesses,
    correctWord,
    setCorrectWord,
    guesses,
    setGuesses,
  } = useWordleContext();

  useEffect(() => {
    resetGame();
  }, []);

  useEffect(() => {
    setGuesses([]);
    setRemainingGuesses(MAX_GUESSES);
    setWord("");
  }, [correctWord]);

  const checkCorrectLettersAndPositions = (guess: string, solution: string) => {
    const result = [];

    for (let i = 0; i < guess.length; i++) {
      if (guess[i] === solution[i]) {
        result.push({ letter: guess[i], status: "correct" });
      } else if (solution.includes(guess[i])) {
        result.push({ letter: guess[i], status: "present" });
      } else {
        result.push({ letter: guess[i], status: "absent" });
      }
    }

    return result;
  };

  const checkWord = () => {
    if (word.length !== MAX_LETTERS || remainingGuesses === 0) {
      return;
    }
    const result = checkCorrectLettersAndPositions(
      word.toUpperCase(),
      correctWord.toUpperCase(),
    );
    setGuesses([...guesses, result]);
    setRemainingGuesses(remainingGuesses - 1);
    setWord("");
  };

  const resetGame = () => {
    setCorrectWord(
      generate({
        exactly: 1,
        minLength: MAX_LETTERS,
        maxLength: MAX_LETTERS,
      })[0],
    );
  };

  return (
    <div className="justify-items-center">
      <div className="absolute right-5 top-5 flex gap-2">
        <Button title="New Word" onClick={() => resetGame()} icon={faRefresh} />
        <Button
          title="Hint"
          icon={faLightbulb}
          onClick={() => alert(correctWord)}
        />
      </div>
      <div className="max-w-sm flex flex-col justify-center">
        <input
          className="bg-[lightgray] p-2 rounded mt-24 mb-8 text-black uppercase sm:mx-16"
          onChange={(ev) => setWord(ev.target.value)}
          onKeyDownCapture={(ev) => {
            if (ev.key === "Enter") {
              checkWord();
            }
          }}
          value={word}
          maxLength={MAX_LETTERS}
        />
        <div className="items-center flex flex-col gap-2">
          {guesses.map((guess, index) => (
            <Guess key={index} guess={guess} />
          ))}
          {new Array(remainingGuesses)
            .fill({ total: 0, subtotal: 0 })
            .map((_, index) => (
              <GuessPlaceholder key={index} />
            ))}
        </div>
        <Button title="Submit" onClick={checkWord} className="my-4 sm:mx-16" />
        <div className="flex gap-3 flex-wrap w-full justify-center sm:px-16">
          {[
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
            "Z",
          ].map((letter, index) => (
            <div
              key={index}
              className={`aspect-square h-6 text-center font-bold rounded content-center ${
                guesses
                  .flat()
                  .map((item) => item.letter)
                  .join("")
                  .toUpperCase()
                  .includes(letter)
                  ? correctWord.toUpperCase().includes(letter)
                    ? "bg-[lightgray] text-[green]"
                    : "bg-[lightgray] text-[red]"
                  : "text-white"
              }`}
              onClick={() => setWord(word + letter)}
            >
              <div className="text-md">{letter}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
