import React from "react";
import Button from "../../components/ui/Button";
import { useGridGameContext } from "../context/GridGameContext";

interface ControlsProps {}

const Controls: React.FC<ControlsProps> = () => {
  const { gamemode, setGamemode } = useGridGameContext();

  return (
    <div>
      <div className="flex justify-center">
        <div className="flex px-1 py-1 my-6 bg-lightgray text-gray rounded-full">
          <button
            className={`px-4 rounded-full ${
              gamemode === "set" ? "bg-primary text-white" : ""
            }`}
            onClick={() => setGamemode("set")}
            disabled={gamemode === "set"}
          >
            Set
          </button>
          <button
            className={`px-4 rounded-full ${
              gamemode === "erase" ? "bg-primary text-white" : ""
            }`}
            onClick={() => setGamemode("erase")}
            disabled={gamemode === "erase"}
          >
            Erase
          </button>
        </div>
      </div>
      <Button
        title="New Game"
        onClick={() => console.log("resetGame")}
        className="flex justify-self-center"
      />
    </div>
  );
};

export default Controls;
