import React from "react";

export const Card = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >,
) => {
  return (
    <div
      className="h-48 aspect-[2/3] bg-red p-4 rounded-lg cursor-pointer"
      {...props}
    >
      <div className="border-white border-4 h-full rounded-lg"></div>
    </div>
  );
};
