import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface ButtonProps {
  onClick: () => void;
  title: string;
  disabled?: boolean;
  className?: string;
  icon?: any;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  title,
  disabled = false,
  className = "",
  icon = null,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`btn text-white ${
        disabled ? "bg-[gray]" : "bg-primary hover:bg-blackhover:scale-105"
      } p-2 px-6 rounded-full ${className}`}
    >
      {title}
      {icon && <FontAwesomeIcon icon={icon} className="ml-2" />}
    </button>
  );
};

export default Button;
