import React from "react";

interface TileProps {
  letter?: string;
  color?: string;
}

const Tile: React.FC<TileProps> = ({ letter, color = "bg-[lightgray]" }) => {
  return (
    <span
      className={`text-black font-bold h-16 w-16 sm:h-12 sm:w-12 aspect-square text-center content-center rounded ${color}`}
    >
      {letter ?? ""}
    </span>
  );
};

export default Tile;
