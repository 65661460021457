import { useSudokuContext } from "../context/SudokuContext";

type NumbersProps = {
  onClickNumber: (number: string) => void;
};

/**
 * React component for the Number Selector in the Status Section.
 */
export const Numbers = ({ onClickNumber }: NumbersProps) => {
  let { gameArray, numberSelected } = useSudokuContext();

  return (
    <div className="status__numbers">
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => {
        if (gameArray.filter((cell) => cell === String(number)).length === 9) {
          return <div />;
        }
        if (numberSelected === number.toString()) {
          return (
            <div
              className="status__number status__number--selected"
              key={number}
              onClick={() => onClickNumber(number.toString())}
            >
              {number}
            </div>
          );
        } else {
          return (
            <div
              className="status__number"
              key={number}
              onClick={() => onClickNumber(number.toString())}
            >
              {number}
            </div>
          );
        }
      })}
    </div>
  );
};
