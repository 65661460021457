import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { Card } from "./Card";

export const GuessingGame: React.FC<{}> = () => {
  const NUMBER_OF_CARDS = 5;

  const [correctCardIndex, setCorrectCardIndex] = React.useState<number>(0);

  function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
  }

  const resetGame = () => {
    setCorrectCardIndex(getRandomInt(NUMBER_OF_CARDS));
  };

  useEffect(() => {
    resetGame();
  }, []);

  const guessCard = (index: number) => {
    if (index === correctCardIndex) {
      toast.success("You win!");
      resetGame();
    } else {
      toast.error("You lose!");
    }
  };

  return (
    <div className="h-screen text-white flex flex-col text-xl">
      <div onClick={resetGame} className="self-center my-10">
        New Game
      </div>
      <div className="flex gap-20 flex-wrap justify-center items-center group">
        {(Array.from(Array(NUMBER_OF_CARDS).keys()) as number[]).map((i) => (
          <Card onClick={() => guessCard(i)} />
        ))}
        {/* <div className="h-64 aspect-[2/3] bg-white text-black rounded-lg relative content-center text-center p-10">
          <div className="absolute left-3 top-3">
            <div>5</div>
            <FontAwesomeIcon icon={faHeart} />
          </div>
          <div className="flex text-3xl gap-8 flex-wrap justify-center">
            <FontAwesomeIcon icon={faHeart} />
            <FontAwesomeIcon icon={faHeart} />
            <FontAwesomeIcon icon={faHeart} />
            <FontAwesomeIcon icon={faHeart} />
            <FontAwesomeIcon icon={faHeart} />
          </div>
          <div className="absolute right-3 bottom-3">
            <div>5</div>
            <FontAwesomeIcon icon={faHeart} />
          </div>
        </div> */}
      </div>
      {/* <div className="text-center grow">{correctCardIndex}</div> */}
    </div>
  );
};
